import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faArrowUp,
    faBox,
    faCalendarAlt,
    faCamera,
    faCaretDown,
    faCheck,
    faCheckCircle,
    faCog,
    faCommentDots,
    faCompressArrowsAlt,
    faCopy,
    faDollarSign,
    faDownload,
    faEdit,
    faEnvelope,
    faExpandArrowsAlt,
    faEye,
    faFileDownload,
    faFlag,
    faListUl,
    faPencilAlt,
    faPercentage,
    faPlus,
    faPrint,
    faQuestionCircle,
    faRubleSign,
    faStop,
    faTimes,
    faTrashAlt,
    faTruck,
    faEyeSlash,
    faSave,
    faRefresh,
} from '@fortawesome/free-solid-svg-icons';

library.add(faTrashAlt, faTimes);
library.add(faPlus);
library.add(faEye);
library.add(faArrowUp);
library.add(faStop);
library.add(faEdit, faPencilAlt);
library.add(faDownload, faFileDownload);
library.add(faCheck, faCheckCircle);
library.add(faAngleLeft, faAngleRight, faAngleUp, faAngleDown, faCaretDown);
library.add(faQuestionCircle);
library.add(faExpandArrowsAlt, faCompressArrowsAlt);
library.add(faCalendarAlt);
library.add(faEnvelope, faCommentDots);
library.add(faCamera);
library.add(faDollarSign);
library.add(faPercentage);
library.add(faCog);
library.add(faPrint);
library.add(faArchive);
library.add(faTruck);
library.add(faRubleSign);
library.add(faBox);
library.add(faCopy);
library.add(faListUl);
library.add(faFlag);
library.add(faEyeSlash);
library.add(faSave);
library.add(faRefresh);
